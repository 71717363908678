import React from 'react';
import BackfromListing from '../BackButton/BackfromListing';
import ActivityFilter from '../Listingfilter/ActivityFilter';

export const ActivityModal = ({ children, onClose, handleFilterChange }) => (
  <div className="Hotel-modal-overlay">
    <div className="Hotel-modal-content"  style={{backgroundColor:"#f7f8fb"}}>
        <BackfromListing onClick={onClose} />
        <div className='children-content'>
            <ActivityFilter onFilterChange={handleFilterChange} />
            {children}
        </div>
      
    </div>
  </div>
);
