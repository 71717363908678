import React from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
import { Secondary } from '../Button/Button';
import Noresultsfound from '../../Assets/Noresultsfound.svg';
import { AllActivitiesdata } from './Activity';

export default function AllActivities({ filters = {}, onSelectActivity  }) {
    // const navigate = useNavigate();
    // const location = useLocation();

    // // Extract the activityToUpdateId from the state passed during navigation
    // const { activityToUpdateId } = location.state || {};

    // Provide default values for filters to avoid undefined errors
    const {
        timeslot = [],
        ActivityDuration = [],
        transfer = [],
        groupType = [],
        interests = [],
        otherFilters = [],
    } = filters;

        // Filter activities based on the provided filters
        const applyFilters = (activity) => {
            if (timeslot.length > 0 && !timeslot.includes(activity.TimeSlot)) return false;
            if (ActivityDuration.length > 0 && !ActivityDuration.includes(activity.ActivityDuration)) return false;
            if (transfer.length > 0 && !transfer.includes(activity.Transfer)) return false;
            if (groupType.length > 0 && !groupType.includes(activity.activitytype)) return false;
            if (interests.length > 0 && !interests.includes(activity.TypeofActivity)) return false;
            if (otherFilters.length > 0 && !otherFilters.includes(activity.KindofActivity)) return false;
            return true;
        };

            // Get filtered activities
    const filteredActivities = AllActivitiesdata.filter(applyFilters);

    // Check if AllActivitiesdata is undefined or empty and return early if true
    if (!AllActivitiesdata || AllActivitiesdata.length === 0) {
        return <p>No activities available.</p>; // Fallback content for undefined or empty data
    }


     // Check if no activities match the filters
     if (filteredActivities.length === 0) {
        return (
            <div className="no-results" style={{ width:"80%", height:"auto"}}>
                <img
                    src={Noresultsfound}
                    alt="No results found"
                    className="no-results-illustration"
                />
                <h2>No results found</h2>
                <p>Try adjusting your filters to find packages.</p>
            </div>
        );
    }

    // // Function to handle activity selection and update the previous activity
    // const handleSelectActivity = (selectedActivity) => {
    //     const packageId = location.state?.packageId;
    //     navigate(`/allactivities/${packageId}`, { state: { updatedActivity: selectedActivity, activityToUpdateId } });
    // };
    

    return (
        <div className="Activity-Listing" style={{display:"grid", gridTemplateColumns:"repeat(2,1fr)",gap:"10px", width:"80%", height:"fit-content"}}>
           {filteredActivities.map((activity) => (
                <div key={activity.Activityid} className="Activity-content-information" style={{ margin: "0%", backgroundColor: "white", height:"auto" }}>
                    <div className="activity-content">
                        <img src={activity.Activityimage} alt="Activityimage" />
                        <div className="activity-details">
                            <p className='Date'>{activity.Date}</p>
                            <h3>{activity.ActivityName}</h3>
                            <ul className='activity-time-type-amount'>
                                <li>{activity.time}</li>
                                <li>{activity.activitytype}</li>
                                <li>{activity.Activityamount}</li>
                            </ul>
                            <ul className='Inclusions'>
                                Inclusions:
                                {activity.Activityinclusions?.map((Inclusion, index) => (
                                    <li key={index}>{Inclusion}</li>
                                ))}
                            </ul>
                            <ul className="Exclusions">
                                Exclusions:
                                {activity.Activityexclusions?.map((Exclusion, index) => (
                                    <li key={index}>{Exclusion}</li>
                                ))}
                            </ul>
                        </div>
                        <div className='insurance-actions'>
                            {/* Select button to choose this activity */}
                            <Secondary buttontext="Select" className="select" onClick={() => onSelectActivity (activity)} />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
