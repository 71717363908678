import React, { useState, useEffect } from 'react';
import { packages } from '../Packagelisting/Packagelisting'; // Import the packages data
import { useParams, useLocation } from "react-router-dom"; // Import the useParams hook
import './DayWiseItinerary.css';
import Textedbutton from '../Button/Textedbutton';
import { ActivityModal } from '../Activity/ActivityModal';
import AllActivities from '../Activity/AllActivities';

export default function ItineraryTimeSlotsComponent({ 
    time, 
    Hoteldescription, 
    Transferdescription, 
    Activitydescription, 
    flightdescription, 
    morningIndex,
    morningAfternoonIndex,
    afternoonIndex,
    eveningIndex,
    afternooneveningIndex,
    fulldayIndex
 }) {
    const { id } = useParams();
    // const navigate = useNavigate();
    const location = useLocation();
    const [showModal, setShowModal] = useState(false); // Track if modal is open
    const [selectedSlot, setSelectedSlot] = useState(null); // Track which slot the user is adding to
    // const [selectedActivity, setSelectedActivity] = useState(null); // Track the selected activity
 

    const packageData = packages.find(pkg => pkg.id === parseInt(id));


    // Fetch the correct day based on dayIndex
    const MorningAfternoon = packageData?.Days[morningAfternoonIndex];
    const Morning = packageData?.Days[morningIndex];
    const Afternoon = packageData?.Days[afternoonIndex];
    const Evening = packageData?.Days[eveningIndex];
    const Fullday = packageData?.Days[fulldayIndex];
    const AfternoonEvening = packageData?.Days[afternooneveningIndex];


    // UseEffect to check for updated activity when coming back from the selection page
    useEffect(() => {
        if (location.state && location.state.updatedActivity && location.state.activityToUpdateId) {
            // const updatedActivity = location.state.updatedActivity;
            // const activityToUpdateId = location.state.activityToUpdateId;

            // If you need to update activities in future, here’s where you would do it
            // For now, activities are removed as they were unused
        }
    }, [location.state]); // Only depend on location.state


    
    // const handleAddActivityClick = (activityId) => {
    //     navigate(`/ActivityListing/${activityId}`, { state: { activityToUpdateId: activityId } });
    // };

        // Open modal to add activity to a specific time slot
        const handleAddActivityClick = (slot) => {
            setSelectedSlot(slot); // Store the time slot (morning/afternoon etc.)
            setShowModal(true); // Show the activity modal
        };
    
        // Handle activity selection from modal
        const handleActivitySelect = (activity) => {
            // setSelectedActivity(activity); // Store the selected activity
            setShowModal(false); // Close modal when activity is selected
            updateSlotWithActivity(activity); // Update the corresponding time slot
        };
    
        const updateSlotWithActivity = (activity) => {
            switch (selectedSlot) {
                case 'morning':
                    packageData.Days[morningIndex].MorningActivity = activity;
                    break;
                case 'afternoon':
                    packageData.Days[afternoonIndex].AfternoonActivity = activity;
                    break;
                // Handle other cases
                default:
                    console.log('Invalid slot'); // You can log or handle invalid cases here
            }
        };
        

    return (
        <div className="timeSlotThree">
            <div className='activity-time'>
                <div className='timeslot'>
                    <p className='time'>{time}</p>
                    
                    {Morning?.MorningAddActivity && (
                        <div className='activity-time1'>
                            <p className='time'>At leisure.</p>
                            <Textedbutton onClick={() => handleAddActivityClick(morningIndex)}  className="addactivity" button="+ Add Activity" />
                        </div>
                    )}
                    {Morning?.MorningHotel && (
                        <div className='timedescription'>
                            {/* <span>{packageData.Hotelicon}</span> */}
                            <p>{Hoteldescription}</p> {/* Replace with hotel description if needed */}
                        </div>
                    )}
                    {Morning?.MorningFlight && (
                        <div className='timedescription'>
                            {/* <span>{packageData.Hotelicon}</span> */}
                            <p>{flightdescription}</p> {/* Replace with hotel description if needed */}
                        </div>
                    )}
                    {Morning?.MorningTransfer && (
                        <div className='timedescription'>
                            {/* <span>{packageData.Hotelicon}</span> */}
                            <p>{Transferdescription}</p> {/* Replace with hotel description if needed */}
                        </div>
                    )}
                    {Morning?.MorningActivity && (
                        <div className='timedescription'>
                            {/* <span>{packageData.Hotelicon}</span> */}
                            <p>{Activitydescription}</p> {/* Replace with hotel description if needed */}
                        </div>
                    )}

                    {MorningAfternoon?.MorningAfternoonAddActivity && (
                        <div className='activity-time1'>
                            <p className='time'>At leisure.</p>
                            <Textedbutton onClick={() => handleAddActivityClick(morningAfternoonIndex)}  className="addactivity" button="+ Add Activity" />
                        </div>
                    )}
                    {MorningAfternoon?.MorningAfternoonFlight && (
                        <div className='timedescription'>
                            {/* <span>{packageData.Flighticon}</span> */}
                            <p>{flightdescription}</p>
                        </div>
                    )}
                    {MorningAfternoon?.MorningAfternoonActivity && (
                        <div className='timedescription'>
                            {/* <span>{packageData.Activityicon}</span> */}
                            <p>{Activitydescription}</p>
                        </div>
                    )}
                    {MorningAfternoon?.MorningAfternoonTransfer && (
                        <div className='timedescription'>
                            {/* <span>{packageData.Transfericon}</span> */}
                            <p>{Transferdescription}</p> {/* Replace with transfer description if needed */}
                        </div>
                    )}
                    {MorningAfternoon?.MorningAfternoonHotel && (
                        <div className='timedescription'>
                            {/* <span>{packageData.Hotelicon}</span> */}
                            <p>{Hoteldescription}</p> {/* Replace with hotel description if needed */}
                        </div>
                    )}

                    {Afternoon?.AfternoonAddActivity && (
                        <div className='activity-time1'>
                            <p className='time'>At leisure.</p>
                            <Textedbutton onClick={() => handleAddActivityClick(afternoonIndex)}  className="addactivity" button="+ Add Activity" />
                        </div>
                    )}
                    {Afternoon?.AfternoonHotel && (
                        <div className='timedescription'>
                            {/* <span>{packageData.Hotelicon}</span> */}
                            <p>{Hoteldescription}</p> {/* Replace with hotel description if needed */}
                        </div>
                    )}
                    {Afternoon?.AfternoonFlight && (
                        <div className='timedescription'>
                            {/* <span>{packageData.Hotelicon}</span> */}
                            <p>{flightdescription}</p> {/* Replace with hotel description if needed */}
                        </div>
                    )}
                    {Afternoon?.AfternoonTransfer && (
                        <div className='timedescription'>
                            {/* <span>{packageData.Hotelicon}</span> */}
                            <p>{Transferdescription}</p> {/* Replace with hotel description if needed */}
                        </div>
                    )}
                    {Afternoon?.AfternoonActivity && (
                        <div className='timedescription'>
                            {/* <span>{packageData.Hotelicon}</span> */}
                            <p>{Activitydescription}</p> {/* Replace with hotel description if needed */}
                        </div>
                    )}

                    {Evening?.EveningAddActivity && (
                        <div className='activity-time1'>
                            <p className='time'>At leisure.</p>
                            <Textedbutton onClick={() => handleAddActivityClick(eveningIndex)} className="addactivity" button="+ Add Activity" />
                        </div>
                    )}
                    {Evening?.EveningHotel && (
                        <div className='timedescription'>
                            {/* <span>{packageData.Hotelicon}</span> */}
                            <p>{Hoteldescription}</p> {/* Replace with hotel description if needed */}
                        </div>
                    )}
                    {Evening?.EveningFlight && (
                        <div className='timedescription'>
                            {/* <span>{packageData.Hotelicon}</span> */}
                            <p>{flightdescription}</p> {/* Replace with hotel description if needed */}
                        </div>
                    )}
                    {Evening?.EveningTransfer && (
                        <div className='timedescription'>
                            {/* <span>{packageData.Hotelicon}</span> */}
                            <p>{Transferdescription}</p> {/* Replace with hotel description if needed */}
                        </div>
                    )}
                    {Evening?.EveningActivity && (
                        <div className='timedescription'>
                            {/* <span>{packageData.Hotelicon}</span> */}
                            <p>{Activitydescription}</p> {/* Replace with hotel description if needed */}
                        </div>
                    )}

                    {AfternoonEvening?.AfternoonEveningAddActivity && (
                        <div className='activity-time1'>
                            <p className='time'>At leisure.</p>
                            <Textedbutton onClick={() => handleAddActivityClick(afternooneveningIndex)} className="addactivity" button="+ Add Activity" />
                        </div>
                    )}
                    {AfternoonEvening?.AfternoonEveningHotel && (
                        <div className='timedescription'>
                            {/* <span>{packageData.Hotelicon}</span> */}
                            <p>{Hoteldescription}</p> {/* Replace with hotel description if needed */}
                        </div>
                    )}
                    {AfternoonEvening?.AfternoonEveningFlight && (
                        <div className='timedescription'>
                            {/* <span>{packageData.Hotelicon}</span> */}
                            <p>{flightdescription}</p> {/* Replace with hotel description if needed */}
                        </div>
                    )}
                    {AfternoonEvening?.AfternoonEveningTransfer && (
                        <div className='timedescription'>
                            {/* <span>{packageData.Hotelicon}</span> */}
                            <p>{Transferdescription}</p> {/* Replace with hotel description if needed */}
                        </div>
                    )}
                    {AfternoonEvening?.AfternoonEveningActivity && (
                        <div className='timedescription'>
                            {/* <span>{packageData.Hotelicon}</span> */}
                            <p>{Activitydescription}</p> {/* Replace with hotel description if needed */}
                        </div>
                    )}

                    {Fullday?.FulldayAddActivity && (
                        <div className='activity-time1'>
                            <p className='time'>At leisure.</p>
                            <Textedbutton onClick={() => handleAddActivityClick(fulldayIndex)} className="addactivity" button="+ Add Activity" />
                        </div>
                    )}
                    {Fullday?.FulldayHotel && (
                        <div className='timedescription'>
                            {/* <span>{packageData.Hotelicon}</span> */}
                            <p>{Hoteldescription}</p> {/* Replace with hotel description if needed */}
                        </div>
                    )}
                    {Fullday?.FulldayFlight && (
                        <div className='timedescription'>
                            {/* <span>{packageData.Hotelicon}</span> */}
                            <p>{flightdescription}</p> {/* Replace with hotel description if needed */}
                        </div>
                    )}
                    {Fullday?.FulldayTransfer && (
                        <div className='timedescription'>
                            {/* <span>{packageData.Hotelicon}</span> */}
                            <p>{Transferdescription}</p> {/* Replace with hotel description if needed */}
                        </div>
                    )}
                    {Fullday?.FulldayActivity && (
                        <div className='timedescription'>
                            {/* <span>{packageData.Hotelicon}</span> */}
                            <p>{Activitydescription}</p> {/* Replace with hotel description if needed */}
                        </div>
                    )}
                    
                </div>
            </div>
             {/* Activity Modal */}
             {showModal && (
                <ActivityModal onClose={() => setShowModal(false)} >
                    <AllActivities onSelectActivity={handleActivitySelect} /> {/* Activity Listing */}
                </ActivityModal>
            )}
        </div>
    );
}
